<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ $t("invite_button") }}</span>
      </v-card-title>
      <v-card-text>
        <IdentityInvite @close:action="closeDialog" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import IdentityInvite from "@/components/IdentityInvite";

export default {
  name: "GsUserInvite",
  components: {
    IdentityInvite
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value(v) {
      if (v) {
        const checkCustomers = this.$store.getters.customers();
        if (checkCustomers || !checkCustomers?.length) {
          this.$store.dispatch("getCustomers");
        }
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("input", false);
    }
  }
};
</script>
