<template>
  <div>
    <template v-if="!Object.keys(value).length">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
          class="ma-4"
        ></v-progress-circular>
        <span>{{ $t("loading") }}</span>
      </div>
    </template>
    <template v-else>
      <v-container style="height: auto" class="d-flex flex-row pa-0 ma-0">
        <!-- <p class="subtitle-2">{{ $t("more_details") }} :</p> -->
        <v-container class="highlighted" style="width: 50%">
          <template
            v-for="(detail, index) in userDetails.filter(p => p.col == 0)"
          >
            <v-row :key="index" dense v-if="detail.value">
              <v-col cols="2">
                <template v-if="detail.label">
                  <span class="font-weight-bold">{{ detail.label }}:</span>
                </template>
              </v-col>
              <v-col>{{ detail.value }}</v-col>
            </v-row>
          </template>
        </v-container>
        <!-- <p class="subtitle-2">{{ $t("more_details") }} :</p> -->
        <v-container class="highlighted" style="width: 50%">
          <template
            v-for="(detail, index) in userDetails.filter(p => p.col == 1)"
          >
            <v-row :key="index" dense v-if="detail.value">
              <v-col cols="2">
                <template v-if="detail.label" class="font-weight-bold">
                  <span class="font-weight-bold">{{ detail.label }}:</span>
                </template>
              </v-col>
              <v-col>{{ detail.value }}</v-col>
            </v-row>
          </template>
        </v-container>
        <!-- <v-col>
            <p class="subtitle-2">{{ $t("action_buttons") }} :</p>
            <v-btn
              class="mr-2"
              small
              color="primary"
              @click="routeTo(`/identities/${value.identityId}/profiles`)"
              >{{ $t("profiles") }}</v-btn
            >
            <v-btn
              class="mr-2"
              small
              color="secondary"
              @click="routeTo(`/identities/${value.identityId}/attrs`)"
              >{{ $t("attributes") }}</v-btn
            >
            <v-btn
              class="mr-2"
              small
              color="warning"
              @click="routeTo(`/identities/${value.identityId}/tags`)"
              >{{ $t("tags") }}</v-btn
            >
            <v-btn
              class="mr-2"
              small
              color="default"
              @click="routeTo(`/identities/${value.identityId}/tokens`)"
              >{{ $t("tokens") }}</v-btn
            >
          </v-col> -->
      </v-container>
    </template>
  </div>
</template>

<script>
import GsAttributesDisplay from "@/components/AttributeEditor/GsAttributesDisplay";
import GsTagsDisplay from "@/components/AttributeEditor/GsTagsDisplay";

export default {
  name: "IdentityDetails",
  components: {
    GsAttributesDisplay,
    GsTagsDisplay
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data: () => ({
    userDetails: []
  }),
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.update();
      }
    }
  },
  async created() {
    await this.update();
  },
  methods: {
    async update() {
      // console.log("update")
      this.userDetails = [
        { label: "ID", value: this.value?.identityId, col: 0 },
        { label: this.$t("email"), value: this.value?.email, col: 0 },
        { label: this.$t("phone"), value: this.value?.phone, col: 0 },
        { label: this.$t("locale"), value: this.value?.locale, col: 0 },
        { label: this.$t("timezone"), value: this.value?.timezone, col: 0 },
        { label: this.$t("ownedBy"), value: this.value?.ownedBy?.name, col: 0 },
        { label: "", value: this.value?.ownedBy?._uri, col: 0 },
        { label: "MF user", value: this.value?.mfUser?.username, col: 0 },
        { label: "", value: this.value?.mfUser?._uri, col: 0 },
        {
          label: this.$t("lastLogin"),
          value: this.getLocaleDate(this.value?.lastLogin),
          col: 0
        },
        {
          label: this.$t("passwordUpdated"),
          value: this.getLocaleDate(this.value?.pwdupd),
          col: 0
        },
        {
          label: this.$t("assetId"),
          value: this.$t("loading"),
          col: 1
        },
        {
          label: this.$t("driverId"),
          value: this.$t("loading"),
          col: 1
        },
        {
          label: this.$t("group"),
          value: this.$t("loading"),
          col: 1
        },
        {
          label: this.$t("createdAt"),
          value: this.getLocaleDate(this.value?.createdAt),
          col: 1
        },
        {
          label: this.$t("createdBy"),
          value: this.value?.createdBy?.name,
          col: 1
        },
        {
          label: "",
          value: this.value?.createdBy?._uri,
          col: 1
        },
        {
          label: this.$t("updatedAt"),
          value: this.getLocaleDate(this.value?.updatedAt),
          col: 1
        },
        {
          label: this.$t("updatedBy"),
          value: this.value?.updatedBy?.name,
          col: 1
        },
        {
          label: "",
          value: this.value?.updatedBy?._uri,
          col: 1
        }
      ];

      setTimeout(async () => {
        let value =
          (await this.getAssetCode(
            this.value?.ownedBy._uri,
            this.value?.assetId
          )) || "-";
        this.userDetails[11].value = value;
      });

      setTimeout(async () => {
        let value =
          (await this.getDriverName(
            this.value?.ownedBy._uri,
            this.value?.driverId
          )) || "-";
        this.userDetails[12].value = value;
      });

      setTimeout(async () => {
        let value = (await this.getGroupNames(this.value?.group)) || "-";
        this.userDetails[13].value = value;
      });
    },
    routeTo(url) {
      this.$router.push({ path: url });
    }
  }
};
</script>

<style>
.highlighted .row:hover {
  background-color: #bcbcbc;
}
</style>
