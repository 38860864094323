<template>
  <v-container fluid>
    <!-- <v-card flat class="mb-3"> -->
    <v-form style="width: 100%" v-model="valid" ref="form" v-if="identity">
      <v-card class="elevation-0">
        <div class="pa-2">
          <v-text-field
            :label="$t('name')"
            v-model="identity.name"
            type="text"
            maxlength="256"
            :rules="formRules.required"
          />
          <v-text-field
            :label="$t('email')"
            v-model="identity.email"
            type="text"
            maxlength="256"
            :rules="formRules.required"
          />
          <v-text-field
            v-if="mode == 'edit'"
            :label="$t('password') + ' (' + $t('optional') + ')'"
            v-model="identity.password"
            type="password"
            maxlength="256"
            :rules="formRules.optional"
          />
          <v-text-field
            :label="$t('phone') + ' (' + $t('optional') + ')'"
            v-model="identity.phone"
            type="text"
            maxlength="256"
            :rules="formRules.optional"
          />
          <GsLocale
            :label="$t('locale')"
            v-model="identity.locale"
            :rules="formRules.required"
          />
          <GsTimeZone
            :label="$t('timezone')"
            v-model="identity.timezone"
            :rules="formRules.required"
          />
          <GsInfiniteDropdown
            :config="customerSelectionConfig"
            v-model="identity.owner"
            :rules="formRules.required"
          />
          <AssetsDropdown
            :label="$t('assetId') + ' (' + $t('optional') + ')'"
            v-model="identity.assetId"
            :rules="formRules.optional"
            :ownedBy="identity.owner"
          />
          <DriversDropdown
            :label="$t('driverId') + ' (' + $t('optional') + ')'"
            v-model="identity.driverId"
            :rules="formRules.optional"
            :ownedBy="identity.owner"
          />
          <GroupsDropdown
            :label="$t('groups')"
            v-model="identity.group"
            :rules="formRules.optional"
            :ownedBy="identity.owner"
          />
          <GsInfiniteDropdown
            :config="permissionTemplatesSelectionConfig"
            v-model="identity.permissionTemplateId"
            :rules="formRules.optional"
          />

          <!-- errors -->
          <div class="d-flex flex-row" v-if="error">
            <span class="subtitle-1 mb-4 red--text"
              >{{ $t("form_invalid_text") }}: {{ error }}</span
            >
          </div>

          <!-- <actions> -->
          <div class="d-flex flex-row">
            <v-spacer />
            <v-btn class="ma-2" color="default" @click="cancel_click">
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              class="ma-2"
              color="primary"
              @click="save_click"
              :loading="loading"
            >
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("save") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import GsLocale from "@/components/FormComponents/GsLocale";
import GsTimeZone from "@/components/FormComponents/GsTimeZone";
import MFUsersDropdown from "@/components/FormComponents/MFUsersDropdown";
import AssetsDropdown from "@/components/FormComponents/AssetsDropdown";
import DriversDropdown from "@/components/FormComponents/DriversDropdown";
import GroupsDropdown from "@/components/FormComponents/GroupsDropdown";
import { GsInfiniteDropdown } from "ngt-frontend-core";

export default {
  name: "IdentityIdentity",
  components: {
    GsLocale,
    GsTimeZone,
    MFUsersDropdown,
    AssetsDropdown,
    DriversDropdown,
    GroupsDropdown,
    GsInfiniteDropdown
  },
  data() {
    return {
      mode: "create",
      permissions: [],
      identity: {
        name: null,
        email: null,
        phone: null,
        locale: null,
        timezone: null,
        owner: null,
        group: null,
        driverId: null,
        assetId: null,
        permissionTemplateId: null
      },
      formRules: {
        required: [
          value =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true
        ],
        requiredArray: [
          value =>
            typeof value === "undefined" ||
            value == null ||
            value == "" ||
            (Array.isArray(value) && value.length == 0)
              ? this.$t("field_required")
              : true
        ],
        optional: [value => true]
      },
      valid: true,
      error: null,
      loading: false
    };
  },
  computed: {
    customerSelectionConfig() {
      return {
        dataConnector: callBffAPI,
        dataFields: {
          uri: "/customers",
          sort: "name:asc",
          fields: "name,_uri",
          search: "name:ic:",
          dataMap: {
            title: "name",
            value: "_uri"
          }
        },
        label: this.$t("ownedBy"),
        limit: 50,
        page: 0,
        rules: this.formRules.required
      };
    },
    permissionTemplatesSelectionConfig() {
      return {
        dataConnector: callBffAPI,
        dataFields: {
          uri: "/permission-templates",
          sort: "name:asc",
          fields: "name,permissionTemplateId",
          search: "name:ic:",
          dataMap: {
            title: "name",
            value: "permissionTemplateId"
          }
        },
        label: this.$t("permissionTemplate"),
        limit: 50,
        page: 0,
        rules: this.formRules.required
      };
    }
  },
  methods: {
    async cancel_click() {
      this.$emit("close:action", false);
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      let url = `${this.$store.state.idp_api_url}/invitations`;
      const method = "POST";
      let data = {
        name: this.identity.name,
        email: this.identity.email,
        locale: this.identity.locale,
        timezone: this.identity.timezone,
        owner: this.identity.owner
      };

      if (this.identity.phone) data.phone = this.identity.phone;
      if (this.identity.driverId != null)
        data.driverId = this.identity.driverId;
      if (this.identity.assetId != null) data.assetId = this.identity.assetId;
      if (this.identity.permissionTemplateId != null)
        data.permissionTemplateId = this.identity.permissionTemplateId;

      data.group = this.identity.group || [];

      try {
        this.loading = true;
        const response = await callAPI({ url, method, data });
        if (response.status == 204 || response.status == 200) {
          this.$emit("close:action", false);
          this.identity = {
            name: null,
            email: null,
            phone: null,
            locale: null,
            timezone: null,
            owner: null,
            group: null,
            driverId: null,
            assetId: null,
            permissionTemplateId: null
          };
        }
      } catch (err) {
        const data = err?.response?.data;

        if (data.statusCode == 400) {
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
