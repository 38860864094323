<template>
  <v-container class="container" fluid>
    <v-card flat class="mb-3">
      <v-btn
        small
        class="elevation-4 mr-4"
        color="primary"
        v-on:click="add_click()"
        >{{ $t("add_button") }}</v-btn
      >
      <v-btn
        small
        class="elevation-4"
        color="primary"
        v-on:click="showInviteUser()"
        >{{ $t("invite_button") }}
      </v-btn>
      <GsUserInvite v-model="showUserInviteModal" />
    </v-card>
    <!-- filter panel -->
    <GsDataFilter
      ref="dataFilter"
      :appSetting="appSettingKey"
      :filters="filters"
      v-on:changed="onFilterChanged"
    />
    <!-- table -->
    <GsDataTable
      ref="table"
      api=""
      endpoint="/identities"
      :appSetting="appSettingKey"
      :headers="headers"
      :default-sort-by="['name']"
      :default-sort-desc="[false]"
      :beforeCallApi="beforeCallApi"
      :afterCallApi="afterCallApi"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="identityId"
      show-expand
      @click:row="rowClicked"
      @after-refresh="afterRefresh"
    >
      <template v-slot:expanded-item="{ headers }">
        <td :colspan="headers.length">
          <GsIdentityDetails v-model="identity" />
        </td>
      </template>

      <template v-slot:item.name="{ item }">
        <span class="subtitle-2">{{ item.name }}</span>
      </template>
      <template v-slot:item.ownedBy="{ item }">
        <span>{{ item.ownedBy?.name }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <GsActionsMenu
          :actions="actions"
          :onActivate="actionId => action_activate(actionId, item)"
        />
      </template>
    </GsDataTable>
    <!-- ROLE SETTINGS MODAL -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="1000"
      persistent
      v-model="openIdentityRoleDialog"
    >
      <v-card>
        <v-toolbar color="primary" dark
          >{{ $t("identityRolesTitle") }}:
          <strong class="ml-1">
            {{ selectedIdentity.name }} ({{ selectedIdentity.email }})
          </strong>
        </v-toolbar>
        <v-card-text class="starter-height">
          <IdentityRoles
            hasOwnConfig
            :ownConfig="permissionsConfig"
            :init="selectedIdentity"
            class="mt-4 starter-height"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="closeIdentityRole">{{ $t("close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import GsIdentityDetails from "@/components/GsIdentityDetails";
import { GsDataTable, GsActionsMenu, GsDataFilter } from "ngt-frontend-core";
import GsUserInvite from "@/components/GsUserInviteModal";
import { IdentityRoles } from "ngt-group-manager-lib";

export default {
  name: "Identities",
  components: {
    GsIdentityDetails,
    GsDataTable,
    GsActionsMenu,
    GsDataFilter,
    GsUserInvite,
    IdentityRoles
  },
  data() {
    return {
      showUserInviteModal: false,
      appSettingKey: "identities",
      expanded: [],
      singleExpand: true,
      initialized: false,
      actions: [
        "attributes",
        "tags",
        "profiles",
        "tokens",
        "identity_roles",
        "edit",
        "delete"
      ],
      // status: 0,
      // statusValues: {
      //   browse: 0,
      //   add: 1,
      //   edit: 2
      // },
      filters: [
        {
          field: "name",
          component: "GsTextFilter",
          params: {}
        },
        {
          field: "identityId",
          component: "GsIdFilter",
          params: {}
        },
        {
          field: "email",
          component: "GsTextFilter",
          params: {}
        },
        {
          field: "ownedBy",
          component: "GsInfiniteDropdownFilter",
          params: {
            options: [],
            clearable: true
          },
          config: {
            dataConnector: callBffAPI,
            dataFields: {
              uri: "/customers",
              sort: "name:asc",
              fields: "name,_uri",
              search: "name:ic:",
              dataMap: {
                title: "name",
                value: "_uri"
              }
            },
            dense: true,
            solo: true,
            flat: true,
            limit: 50,
            page: 0,
            value:
              this.$store.state?.appSettings?.identities?.filter?.ownedBy
                ?.value || null
          }
        },
        {
          field: "driverId",
          component: "GsIdFilter",
          params: {}
        },
        {
          field: "assetId",
          component: "GsIdFilter",
          params: {}
        }
      ],
      identity: null,
      formConfig: {},
      selectedIdentity: {},
      openIdentityRoleDialog: false
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("actions"),
          width: "10%",
          sortable: false,
          value: "actions"
        },
        {
          text: this.$t("name"),
          width: "30%",
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("email"),
          width: "25%",
          sortable: false,
          value: "email"
        },
        {
          text: this.$t("ownedBy"),
          width: "25%",
          align: "left",
          sortable: false,
          value: "ownedBy"
        },
        {
          text: "",
          value: "data-table-expand",
          align: "right"
        }
      ];
    },
    permissionsConfig() {
      return {
        dataConnector: callBffAPI,
        // applicationTitle: "Report Manager",
        forSuperAdmins: true
      };
    }
  },
  // computed: {
  //   customers() {
  //     return this.$store.state.customers;
  //   }
  // },
  // watch: {
  //   customers(v) {
  //     if (!v) return;
  //     const options = v.map(p => ({ value: p._uri, text: p.name }));
  //     this.filters.find(p => p.field == "ownedBy").params.options = options;
  //   }
  // },
  async mounted() {
    this.initialized = false;
    this.$nextTick(() => {
      this.initialized = true;
    });
    // check if customers are available?
    // const checkCustomers = this.$store.getters.customers();
    // if (checkCustomers || !checkCustomers?.length) {
    //   this.$store.dispatch("getCustomers");
    // }
  },
  methods: {
    async refreshTable() {
      try {
        // console.log("refreshTable");
        await this.$refs.table.refreshTable();
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async beforeCallApi(params) {
      params.url += this.$refs.dataFilter.getFilter();
      params.url +=
        "&r8sFields=ownedBy.name,createdBy.name,updatedBy.name,mfUser.username";
      return params;
    },
    async afterCallApi(params) {
      return params;
    },
    afterRefresh({ items }) {
      const expandedId = this.loadExpanded();
      if (expandedId) {
        const expandItem = this.$refs.table.items.find(
          p => p.identityId == expandedId
        );
        if (expandItem) {
          this.expand(expandItem);
        }
      }
    },
    onFilterChanged() {
      this.$refs.table.goToFirstPage();
    },
    async add_click() {
      this.$router.push_safe(`/identities/new`);
    },
    async edit_click(item) {
      this.expand(item);
      this.$router.push_safe(`/identities/${item.identityId}`);
    },
    // async cancel_click() {
    //   this.status = this.statusValues.browse;
    // },
    // async save_click() {
    //   this.status = this.statusValues.browse;
    //   await this.refreshTable();
    // },
    async attributes_click(item) {
      this.expand(item);
      this.$router.push_safe(`/identities/${item.identityId}/attrs`);
    },
    async tags_click(item) {
      this.expand(item);
      this.$router.push_safe(`/identities/${item.identityId}/tags`);
    },
    async profiles_click(item) {
      this.expand(item);
      this.$router.push_safe(`/identities/${item.identityId}/profiles`);
    },
    async tokens_click(item) {
      this.expand(item);
      this.$router.push_safe(`/identities/${item.identityId}/tokens`);
    },
    async groups_click(item) {
      let state = this.$store.state.appSettings["groups"]["filter"] || {};
      state.ownedBy = { value: item.ownedBy._uri };
      this.$store.commit("setAppSetting", {
        parent: "groups",
        key: "filter",
        value: this.cloneObj(state)
      });
      this.$router.push_safe(`/groups`);
    },
    async delete_click(item) {
      let result = confirm(this.$t("delete_modal") + "\n\n" + item.name);
      if (!result) return;

      await callAPI({
        url: this.$store.state.idp_api_url + "/identities/" + item.identityId,
        method: "DELETE"
      });
      await this.refreshTable();
    },
    identity_roles_click(item) {
      this.selectedIdentity = item;
      this.openIdentityRoleDialog = true;
    },
    closeIdentityRole() {
      this.selectedIdentity = {};
      this.openIdentityRoleDialog = false;
    },
    async action_activate(actionId, item) {
      switch (actionId) {
        case "attributes":
          this.attributes_click(item);
          break;
        case "tags":
          this.tags_click(item);
          break;
        case "profiles":
          this.profiles_click(item);
          break;
        case "tokens":
          this.tokens_click(item);
          break;
        case "identity_roles":
          this.identity_roles_click(item);
          break;
        case "edit":
          this.edit_click(item);
          break;
        case "delete":
          this.delete_click(item);
          break;
      }
    },
    rowClicked(item) {
      const earlyReturn = this.expanded.some(
        p => p.identityId === item.identityId
      );
      if (earlyReturn) {
        this.expanded = [];
        this.saveExpanded(null);
        return;
      }
      this.expand(item);
    },
    expand(item) {
      if (!this.$refs.table) return;
      if (item) {
        this.expanded = [item];
        this.saveExpanded(item.identityId);
        this.identity = item;
      }
    },
    showInviteUser() {
      this.showUserInviteModal = true;
    },
    async fillOwnedByOptions() {
      const checkCustomers = this.$store.getters.customers();
      if (checkCustomers || !checkCustomers?.length) {
        this.$store.dispatch("getCustomers");
      }
    }
  }
};
</script>

<style>
.starter-height {
  min-height: 40vh;
}
</style>
